/* Default font size for both desktop and mobile */
body {
  background-color: #77D4FC;
  font-size: 16px;
  font-family: 'Roboto';
}

/* Media query for desktop devices with a minimum width of 768px */
@media (min-width: 768px) {
  body {
    font-size: 18px;
    /* Larger font size for desktop devices */
  }
}

/* Media query for phones with a maximum width of 767px */
@media (max-width: 767px) {
  body {
    font-size: 14px;
    /* Smaller font size for phones */
  }
}

.App {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

.App-logo {
  height: 5vh;
  padding: 1%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.App-header,
.App-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}