/* Default font size for both desktop and mobile */
body,
input,
textarea,
button {
    font-size: 16px;
    font-family: 'Roboto';
}

/* Media query for desktop devices with a minimum width of 768px */
@media (min-width: 768px) {

    body,
    input,
    textarea,
    button {
        font-size: 18px;
        /* Larger font size for desktop devices */
    }
}

/* Media query for phones with a maximum width of 767px */
@media (max-width: 767px) {

    body,
    input,
    textarea,
    button {
        font-size: 14px;
        /* Smaller font size for phones */
    }
}

.contact-form {
    margin: auto;
    align-items: center;
}

.from-group {
    display: block;
}

input,
textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
}

input {
    width: 80%
}

textarea {
    width: 80%;
    height: 10vmax;
}

input.error,
textarea.error {
    border-color: #ff0000;
}

button {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.success-message {
    text-align: center;
    color: #007bff;
    margin-top: 20px;
}